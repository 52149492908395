import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../less/cms.less'

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <div className="cms-container">
        <h1 className="cms-container__title">Privacy Policy</h1>
        <div className="cms-container__wrapper cms-container__wrapper__terms">
          <p>
            <strong>Last updated: December 13, 2021.</strong>
          </p>
          <p>
            The MealPal website and mobile applications (the “Site”) are operated by MealPal, Inc. (“MealPal”). MealPal
            is committed to respecting and protecting your privacy. To that end, we have prepared this privacy policy
            (“Privacy Policy”) to describe our collection, use, and disclosure of your information in connection with
            your use of the Site. This Privacy Policy does not apply to the practices of third parties that we do not
            own or control, including but not limited to any chefs, restaurants, or food service providers we may
            partner with and any third party websites, services, and applications (“Third Party Services”), or to any
            individuals that we do not manage or employ. Please read this Privacy Policy carefully so that you
            understand our privacy practices. Any defined terms not defined herein shall have the meaning attributed to
            them in the MealPal Terms of Use.
          </p>
          <p>
            By using this Site, you agree to this Privacy Policy, and consent to the collection, transfer, manipulation,
            storage, disclosure, and other uses of your information as described in this Privacy Policy. If you do not
            agree to this Privacy Policy, do not use the Site or give us any of your information.
          </p>
          <p>
            We may occasionally update this Privacy Policy. The date of the most recent update of this document will
            always be displayed at the beginning of this page. If we change our Privacy Policy, we will post those
            changes on mealpal.com. If we decide to collect or use Personally Identifiable Information in a manner
            different from that stated at the time it was collected, we will notify users via email (if an email address
            has been provided) and/or display a notice on mealpal.com. Your continued use of this Site constitutes your
            agreement to this Privacy Policy and any updates. We encourage you to periodically review this Privacy
            Policy to stay informed about how we are protecting the Personally Identifiable Information we collect.
          </p>
          <p>
            The terms “MealPal,” “we,” “us,” and “our” refer to MealPal and its subsidiaries, agents and affiliates. The
            terms “you,” “your,” and “yours” refer to the user/customer utilizing the Site. This Privacy Policy is
            incorporated by reference in, and governed by, our Terms of Use, which may be found at{' '}
            <a
              href={`${process.env.GATSBY_LANDING_PAGE_URL}/terms-and-conditions/`}
            >{`${process.env.GATSBY_LANDING_PAGE_URL}/terms-and-conditions/`}</a>
            .
          </p>
          <ol type="1">
            <li>
              CHILDREN
              <p>
                The Site is not intended for or directed to persons under the age of 13, and we will not knowingly
                collect information from such persons. Any person who provides information to us through registration or
                in any other manner on the Site represents to MealPal that they are 13 years of age or older. If we
                learn that a child under 13 has submitted personal information to us, we will attempt to delete the
                information as soon as possible.
              </p>
            </li>
            <li>
              COLLECTION OF INFORMATION
              <p>
                In connection with certain aspects of the Site, we may request, collect and/or display some of your
                Personally Identifiable Information. “Personally Identifiable Information” means any information which
                can be used to identify an individual, and may include, but is not limited to, name, email address,
                phone number, or postal or other physical address. We collect Personally Identifiable Information from
                you when you register for the Site, including your name and email address. To participate in certain
                activities and to obtain certain services or documents offered on the Site, you may be asked to provide
                additional Personally Identifiable Information. Financial information, such as your Payment Method
                information, type, and expiration date, is collected and stored by Stripe, our third-party payment
                processing company (“Payment Processor”), and use and storage of that information is governed by the
                Stripe’s terms of service (which may found at{' '}
                <a href="https://stripe.com/payment-terms/legal"> https://stripe.com/payment-terms/legal</a>) and
                privacy policy (which, for US users, may be found at{' '}
                <a href="https://stripe.com/us/privacy"> https://stripe.com/us/privacy</a>, and for Canadian users, may
                be found at <a href="https://stripe.com/ca/privacy"> https://stripe.com/ca/privacy</a>). If you register
                a credit card as your Payment Method, MealPal may collect your credit card’s last four (4) digits and/or
                customer verification number for account information and verification purposes only, and will in no case
                have your full credit card information.
              </p>
              <p>
                We endeavor to limit our requests for Personally Identifiable Information to information we believe is
                reasonably necessary to achieve our purposes as described in the “Information Use” section below. Any
                Personally Identifiable Information received in communication or in material you transmit to the Site by
                electronic mail or otherwise are and will be treated in accordance with this Privacy Policy.
              </p>
              <p>
                Some features of the Site allow you to provide content, such as written reviews. All content submitted
                by you to the Services may be retained by us indefinitely, even after you terminate your Account. We may
                continue to disclose such content to third parties in a manner that does not reveal Personally
                Identifiable Information, as described in this Privacy Policy.
              </p>
              <p>
                Our Site may include features that enable users to input their locations and utilize mapping services
                for the purpose of finding restaurants or meals in their area (“Location-Based Services”). To the extent
                you voluntarily opt to use any Location-Based Services, information about your inputted location at the
                time you use those Location-Based Services may be reflected in your cookie information. However, we do
                not track your movement or collect or store information regarding your map usage when you use any
                Location-Based Services. We may collect and/or receive location information from your use of the Site
                generally, which can come from a variety of sources, depending on how you use or access the Site.
              </p>
              <p>
                Generally, the Site automatically collects usage information, such as the number and frequency of
                visitors to the Site and users of the Services. We may use this data in aggregate form, that is, as a
                statistical measure, or in other anonymous forms, but not in a manner that would identify you
                personally. This type of data enables us and third parties authorized by us to figure out how often
                individuals use parts of the Site so that we can analyze and improve them.
              </p>
              <p>
                We automatically receive and record information from your web browser when you interact with the Site,
                including your IP address and cookie information. This information is used for fighting spam/malware and
                also to facilitate collection of data concerning your interaction with the Services (e.g., what links
                you have clicked on). While we collect and store IP address information, that information is not made
                public. We do at times, however, share this information with our partners, service providers and other
                persons with whom we conduct business, and as otherwise specified in this Privacy Policy.
              </p>
              <p>
                We may also collect some device-specific information if you access the Site using a mobile device.
                Device information may include but is not limited to unique device identifiers, network information, and
                hardware model, as well as information about how the device interacts with our Site.
              </p>
            </li>
            <li>
              INFORMATION USE
              <p>MealPal uses Personally Identifiable Information for several general purposes:</p>
              <ul>
                <li>to provide you with the Site, service information, and news,</li>
                <li>to support the Site and services offered on or through the Site,</li>
                <li>to personalize and improve the Site for our members,</li>
                <li>
                  to enable the sharing of MealPal member meal and restaurant choices with social media contacts, other
                  MealPal members and professional colleagues,
                </li>
                <li>to contact you for feedback about our services,</li>
                <li>to conduct research about MealPal’s customer base or services,</li>
                <li>to fulfill your reservation and order requests,</li>
                <li>to process your payments including credit checks and collection,</li>
                <li>to notify you of technical updates or changes in policy, or</li>
                <li>to contact you for our own marketing and promotional purposes, and</li>
                <li>
                  to process contests, sweepstakes, or other promotions and fulfill any related awards or discounts.
                </li>
              </ul>
              <p>
                We may also use non-Personally Identifiable Information, such as demographic or usage data, to analyze
                and develop our marketing strategy and to alter, modify, introduce, or further improve any aspect,
                feature, or policy of the Site and our services.
              </p>
            </li>
            <li>
              INFORMATION SHARING AND DISCLOSURE
              <p>
                We will not sell or rent your Personally Identifiable Information to third parties except as described
                in this Privacy Policy. You agree that we may disclose your Personally Identifiable Information to our
                agents or to other companies or people under any of the following circumstances:
              </p>
              <ul>
                <li>
                  to any applicable chef, merchant, restaurant, or food service provider for each meal that you sign up
                  for on the Site, so it can contact you regarding the meal you reserved and send you other
                  communication that may be of interest as special offers (you may opt-out of marketing communications
                  directly with the applicable merchant).
                </li>
                <li>
                  if sharing the information is reasonably necessary to provide or otherwise make available the Site and
                  any feature of the Site or a service that you have requested.
                </li>
                <li>
                  to keep you up to date on the latest product announcements, software updates, special offers, or other
                  information we think you would like to hear about either from us or from our business partners (unless
                  you have opted out of these types of communications). (Note that you will not be able to opt-out of
                  service announcements that contain important information relevant to your use of the Site and are not
                  promotional in nature.)
                </li>
                <li>
                  to share MealPal member meal and restaurant choices with your social media contacts, other MealPal
                  members, and professional colleagues, including via third party social media sites;
                </li>
                <li>
                  if we believe in good faith that we are required to do so by law, in connection with litigation, to
                  prevent a crime, or to protect personal property, the public, or the Site.
                </li>
                <li>
                  in connection with a sale or merger with another entity, consolidation, restructuring, sale or
                  purchase of assets, financing or other corporate change, including during the course of any due
                  diligence process or if MealPal should ever file for bankruptcy or related proceeding. In any of these
                  scenarios, user information would be one of the assets that is transferred or acquired by a third
                  party. You acknowledge that such transfers may occur, and that any acquirer of us or our assets may
                  continue to use your Personal Information as set forth in this policy.
                </li>
                <li>
                  to your employer (or similar entity), if you participate in any of enterprise solutions or corporate
                  programs.
                </li>
                <li>when we otherwise have your consent to share the information.</li>
              </ul>
              <p>
                While MealPal may undertake efforts to see that any third party to which MealPal discloses Personally
                Identifiable Information as outlined above is under an obligation to use that Personally Identifiable
                Information solely for the purposes for which the information was disclosed, MealPal exercises no
                control over these and other independent third parties. MealPal is not responsible for, and will not be
                liable for, the conduct, actions, omissions, or information handling or dissemination practices of third
                parties.
              </p>
              <p>
                MealPal may also share non-Personally Identifiable Information with third parties for the purpose of
                understanding how and how often people use our Site and the services or websites of those third parties,
                which facilitates improving our Site, their services and websites, and how our Site interfaces with
                their services and websites. In addition, third parties may share with us non-Personally Identifiable
                Information about you that they have independently developed or acquired.
              </p>
            </li>

            <li>
              CHOICE
              <p>
                We will give you the opportunity to “opt out” of receiving direct marketing or market research
                information through the unsubscribe button on those email communications, as well as the opportunity to
                “opt out” of having your ordering information shared with your social media contacts, other MealPal
                members, and professional colleagues, through the “My Account” section of the website. You can choose to
                opt out of certain communications or communication methods; however, if you do so, you may not be able
                to take advantage of some of our Site’s features. This means we assume you have given us your consent to
                collect and use your information in accordance with this Privacy Policy unless you take affirmative
                action to indicate that you do not consent, for instance by clicking or checking the appropriate option
                or box at the point of collection.
              </p>
            </li>
            <li>
              ACCESS
              <p>
                You may correct, amend, or delete your Personally Identifiable Information by editing your profile on
                the Site or emailing us at <a href="mailto:hello@mealpal.com">hello@mealpal.com</a>. Should you ever
                decide to delete your Account, you may do so by emailing us at{' '}
                <a href="mailto:hello@mealpal.com">hello@mealpal.com</a>. If you terminate your Account, any association
                between your account and information we store will no longer be accessible through your account.
                However, any public activity on your account prior to deletion will remain stored on our servers and
                will remain accessible to the public.
              </p>
              <p>
                Under California Civil Code sections 1798.83-1798.84, California residents are entitled to ask us for a
                notice identifying the categories of personal customer information which we share with our affiliates
                and/or third parties for marketing purposes, and providing contact information for such affiliates
                and/or third parties. If you are a California resident and would like a copy of this notice, please
                submit a written request to <a href="mailto:hello@mealpal.com">hello@mealpal.com</a>, or to the
                following address: 264 W. 40th Street, Unit 1303, New York, NY 10018.
              </p>
              <p>
                If you are a resident of the State of California, please see <Link to="/ca-privacy">this page</Link> for
                additional California privacy disclosures
              </p>
            </li>
            <li>
              SECURITY
              <p>
                We are committed to protecting Personally Identifiable Information from loss, misuse, disclosure,
                alteration, unauthorized access, and destruction. MealPal takes reasonable precautions to safeguard the
                confidentiality of Personally Identifiable Information, using industry-standard techniques, including a
                Transport Layer Security (TLS) protocol in our communications with you. However, we do not guarantee or
                warrant that such techniques will prevent unauthorized access to information about you that we store,
                including Personally Identifiable Information. Unauthorized entry or use, hardware or software failure,
                and other factors, may compromise the security of user information at any time. Additionally, you are
                responsible for preventing unauthorized access to your account and Personally Identifiable Information
                by protecting your account credentials and limiting access to devices on which you access or use the
                Site.
              </p>
            </li>

            <li>
              THIRD PARTY SERVICES
              <p>
                The Site may contain links to, or allow sharing with, Third Party Services, such as your social media
                account (e.g. Facebook, Twitter, etc.). As stated above, MealPal is not responsible for the privacy
                practices of such other sites. We encourage our users to be aware when linking to or sharing with a
                Third Party Service to read the privacy statements of any Third Party Service that collects Personally
                Identifiable Information.
              </p>
            </li>
            <li>
              COOKIES
              <p>
                We use cookies and other similar technologies (such as actions tags, also known as beacons, or pixels
                tags) for a number of purposes including to remember preferences, track conversions, conduct marketing
                and promotional efforts, analyze site traffic and trends, and generally understand the online behaviors
                and interests of people who interact with our Site.
              </p>
              <p>
                We use third-party advertising companies to serve advertisements on our behalf or to provide
                advertising-related services. These companies may use a cookie or an action tag, to tailor the
                advertisements you see on this Site and other sites, to track your response to their advertisement, to
                determine whether advertising has been served and to measure the effectiveness of their advertising or
                to award incentives or points to their members who respond to their advertisements. The cookies, action
                tags or similar files do not contain any Personal Information.
              </p>
              <p>
                You can learn more about targeted advertising, its benefits and your choices by going to the Digital
                Advertising Alliance site at{' '}
                <a href="http://www.aboutads.info/consumers/" target="_blank" rel="noreferrer">
                  http://www.aboutads.info/consumers/
                </a>
                . The targeted advertising companies we work with are members of the Network Advertising Initiative or
                the DAA. The NAI and the DAA allow you to limit targeted advertising from its members by using an
                opt-out tool available on the NAI website at{' '}
                <a href="http://www.networkadvertising.org/" target="_blank" rel="noreferrer">
                  networkadvertising.org
                </a>{' '}
                or the DAA’s site.
              </p>
              <p>
                We may also use third parties to provide measurement and analytic services. You can find more
                information on Google Analytics by visiting{' '}
                <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">
                  https://tools.google.com/dlpage/gaoptout
                </a>
                .
              </p>
            </li>
            <li>
              SOCIAL MEDIA
              <p>
                Our Site may integrate with social sharing features or other related tools which enable you or us to
                share actions you take on our Site with Third Party Services such as social media services, and you can
                also use your account on the Site to interact with your accounts on social media services. For example,
                you may be able to access posting and sharing tools on the Site that allow you to post information to
                your social media account, or you may interact with us through a social media service such as by tagging
                MealPal or sharing an order made on MealPal. When you use these tools or tag or mention MealPal, or
                otherwise interact with MealPal through social media services, you acknowledge and agree that some
                Personally Identifiable Information and other content relating to your account on the applicable social
                media services may be transmitted to us, and you thereby allow us to use such information or content in
                connection with our business, including for commercial purposes, or as otherwise permitted by this
                Privacy Policy. Furthermore, when you use our Site to interact with social media services, we may share
                some of your information and content from our Site with those social media services (including through
                our branded pages), and such information would then be subject to the privacy policies of such social
                media services, and your privacy settings with those services. Therefore, we encourage you to read the
                privacy policies and terms and conditions of any social media services or other Third Party Services
                that you use in connection with the Site.
              </p>
              <p>
                Additionally, we may collect and receive your social media account login credentials in connection with
                providing you with the Site, but only if you expressly provide us with such information. We will only
                use such login information as set forth in this Privacy Policy or as otherwise expressly authorized by
                you.
              </p>
            </li>
            <li>
              PUBLIC POSTS
              <p>
                The Site may allow you to post or share content to a public area of the Site such as a public profile,
                group, community or other public area. You should be aware that any content or information you choose to
                disclose in these areas can be read, collected and used by other users, the general public and other
                sites (including search engines). We are not responsible for, and this Privacy Policy does not apply to,
                information you may choose to post in these areas.
              </p>
            </li>
          </ol>
          <h3>YOUR CONSENT TO THIS PRIVACY POLICY</h3>
          <p>
            By using this Site, you agree to this Privacy Policy. We may occasionally update this Privacy Policy. The
            date of the most recent update of this document will always be displayed at the beginning of this page. If
            we change our Privacy Policy, we will post those changes on mealpal.com If we decide to use Personally
            Identifiable Information in a manner different from that stated at the time it was collected, we will notify
            users via email (if an email address has been provided) or display a notice on mealpal.com. We encourage you
            to periodically review this Privacy Policy to stay informed about how we are protecting the Personally
            Identifiable Information we collect. Your continued use of this Site constitutes your agreement to this
            Privacy Policy and any updates.
          </p>

          <h3>CONTACTING MEALPAL</h3>
          <p>If you have any questions, comments, or concerns about this Privacy Policy, please contact us at:</p>
          <p>
            MealPal, Inc. <br></br>
            264 W. 40th Street, Unit 1303, <br></br>
            New York, NY 10018
          </p>
          <p>
            Email:
            <a href="mailto:hello@mealpal.com">hello@mealpal.com</a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default PrivacyPolicy
